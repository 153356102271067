<template>
  <main class="main-container">
    <div>
      <h1 class="-xltb title column-span-3-1">
        Register your Organization profile
      </h1>
      <p class="-str column-span-3-1" style="color: var(--gray3)">
        Our team will validate your organization's profile.
      </p>
    </div>

    <OrgDetailsForm @handleClick="handleClick" />
  </main>
</template>

<script>
import OrgDetailsForm from "../../components/forms/organizations/OrgDetailsForm.vue";
export default {
  components: {
    OrgDetailsForm,
  },
  methods: {
    handleClick(data) {
      console.log("DATA :", data);
    },
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  gap: 2rem;
}
.main-container > div:first-child {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-inline: 1rem;
}
.main-container > h1 {
  padding-left: 8px;
}
.main-container > p {
  padding-left: 8px;
}
</style>